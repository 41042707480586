<template>
  <div class="device">
    <div class="formWrap">
      <span class="formName">设备状态</span>
      <el-select
        class="formBody"
        v-model="deviceState"
        clearable
        placeholder="请选择"
      >
        <el-option label="在线" value="0" />
        <el-option label="离线" value="1" />
      </el-select>
      <span class="formName">设备SN码</span>
      <el-input
        class="formBody"
        v-model="deviceSn"
        clearable
        placeholder="请输入内容"
      ></el-input>
      <span class="formName">设备类型</span>
      <el-select
        class="formBody"
        v-model="deviceType"
        clearable
        placeholder="请选择"
      >
        <el-option label="扬尘噪音" value="1" />
        <el-option label="塔式起重机" value="2" />
        <el-option label="施工升降机" value="3" />
      </el-select>
      <span class="formName">设备厂商</span>
      <el-select
        class="formBody"
        v-model="manufacturerId"
        clearable
        placeholder="请选择"
      >
        <el-option
          v-for="(item, index) in manufacturerList"
          :key="index"
          :label="item.manufacturerName"
          :value="item.id"
        />
      </el-select>

      <el-button class="mr10" type="primary" @click="onList()">查询</el-button>
      <el-button @click="onReList()">重置</el-button>
    </div>

    <el-button type="primary" icon="el-icon-plus" v-if="!isAdmin" @click="handleAdd"
      >添加</el-button
    >

    <el-table :data="listData" class="list">
      <el-table-column label="序号" type="index" width="50"> </el-table-column>
      <el-table-column label="设备状态" width="80">
        <template slot-scope="scope">
          <span class="green" v-if="scope.row.deviceState == 0">在线</span>
          <span class="red" v-if="scope.row.deviceState == 1">离线</span>
        </template>
      </el-table-column>
      <el-table-column label="设备SN码" prop="deviceSn"> </el-table-column>
      <el-table-column label="设备归属" prop="name">
      </el-table-column>
      <el-table-column label="设备类型" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.deviceType == 1">扬尘噪音</span>
          <span v-if="scope.row.deviceType == 2">塔式起重机</span>
          <span v-if="scope.row.deviceType == 3">施工升降机</span>
        </template>
      </el-table-column>
      <el-table-column label="设备厂商" prop="manufacturerName">
      </el-table-column>
      <el-table-column label="创建时间" prop="createTime" width="180">
      </el-table-column>
      <el-table-column label="上报状态" width="120" v-if="isAdmin">
        <template slot-scope="scope">
          <el-button @click="handleReportingStatus(scope.row)" v-if="scope.row.reportingStatus == 0" type="success" size="mini" icon="el-icon-success" plain>允许</el-button>
          <el-button @click="handleReportingStatus(scope.row)" v-if="scope.row.reportingStatus == 1" type="danger" size="mini" icon="el-icon-error" plain>不允许</el-button>
        </template>
      </el-table-column>
      <el-table-column label="上报状态" width="120" v-if="!isAdmin">
        <template slot-scope="scope">
          <span class="green" v-if="scope.row.reportingStatus == 0">允许</span>
          <span class="red" v-if="scope.row.reportingStatus == 1">不允许</span>
        </template>
      </el-table-column>


      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button size="mini" @click="showData(1, scope.row)"
            >当日数据</el-button
          >
          <el-button size="mini" @click="showData(2, scope.row)"
            >历史数据</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @current-change="onList"
      :current-page.sync="pageNum"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="totals"
    >
    </el-pagination>


    <!-- 数据看板 -->
    <el-dialog
      :title="data_title"
      :visible.sync="data_show"
      @close="closeData()"
      width="80%"
      fullscreen
    >
      <el-date-picker
        v-if="data_type == 2"
        :append-to-body="false"
        popper-class="tab04f1-date-picker"
        :picker-options="pickerOptions"
        v-model="dateRange"
        type="daterange"
        value-format="yyyy-MM-dd"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="margin-bottom: 20px; margin-right: 10px;"
      >
      </el-date-picker>
      <el-button type="primary" v-if="data_type == 2" @click="onDataList">查询</el-button>
      <el-table :data="data_listData" border>
        <el-table-column v-if="data_listKeys.length" type="index" label="序号" width="50"></el-table-column>
        <el-table-column v-for="(item, index) in data_listKeys" :key="index" :prop="item" :label="data_listValues[index]"></el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="onDataList"
        :current-page.sync="data_pageNum"
        :page-size="data_pageSize"
        :page-sizes="[5, 10, 20, 30, 50, 100]"
        layout="sizes, total, prev, pager, next"
        :total="data_totals"
      >
      </el-pagination>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeData()">返回</el-button>
      </div>
    </el-dialog>

    <!-- 添加面板 -->
    <el-dialog title="添加设备" :visible.sync="add_show">
      <el-form>
        <el-form-item label="设备SN码" label-width="120px">
          <el-input class="formBody" v-model="add_deviceSn" autocomplete="off" clearable></el-input>
        </el-form-item>
        <el-form-item label="设备类型" label-width="120px">
          <el-select class="formBody" v-model="add_deviceType" placeholder="请选择" clearable>
            <el-option label="扬尘噪音" :value="1" :disabled="userData.devicePermission.dust == 0" />
            <el-option label="塔式起重机" :value="2" :disabled="userData.devicePermission.tower == 0" />
            <el-option label="施工升降机" :value="3" :disabled="userData.devicePermission.elevator == 0" />
          </el-select>
        </el-form-item>
        <el-form-item label="设备厂商" label-width="120px">
          <el-select class="formBody" v-model="add_manufacturerId" placeholder="请选择" clearable>
            <el-option
              v-for="(item, index) in manufacturerList"
              :key="index"
              :label="item.manufacturerName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.device {
  padding: 20px;
}

.green {
  color: green;
}
.red {
  color: red;
}
.el-date-editor {
  position: relative;
}
::v-deep .tab04f1-date-picker {
  position: absolute !important;
  left: 40px !important;
  top: 40px !important;
}
</style>

<script>
export default {
  name: "Device",
  components: {},
  data() {
    return {
      userData: {
        id: null,
        userId: null,
        appId: null,
        appSecret: null,
        permission: null,
        devicePermission: {
          dust: null,
          tower: null,
          elevator: null,
        },
      },

      manufacturerList: [],
      deviceState: "",
      deviceSn: "",
      deviceType: "",
      manufacturerId: "",

      listData: [],
      pageNum: 1,
      pageSize: 10,
      totals: 0,

      dateRange: ["", ""],
      pickerOptions: {
        disabledDate: time => {
          return (
            time.getTime() > Date.now() - 24*60*60*1000
          )
        }
      },
      data_deviceSn: "",
      data_manufacturerId: "",
      data_dataTypeId: "",
      data_deviceType: "",
      data_reportingType: "",
      
      data_type: 1,
      data_show: false,
      data_title: "",
      data_listData: [],
      data_listKeys: [],
      data_listValues: [],
      data_pageNum: 1,
      data_pageSize: 5,
      data_totals: 0,
      
      add_show: false,
      add_deviceSn: null,
      add_deviceType: null,
      add_manufacturerId: null,
    };
  },
  computed: {
    isAdmin: function () {
      return localStorage.getItem("roleLevel") == 1;
    },
  },
  watch: {
    deviceState: function () {
      this.pageNum = 1
      this.onList()
    },
    deviceType: function () {
      this.pageNum = 1
      this.onList()
    },
    manufacturerId: function () {
      this.pageNum = 1
      this.onList()
    },
  },
  mounted() {
    this.onList();
    this.onGetManufacturerList();
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取厂商列表
    onGetManufacturerList: function () {
      var that = this;
      var data = {
        manufacturerName: "",
        pageNum: 0,
        pageSize: 0,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/manufacturer/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.manufacturerList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取列表数据
    onList: function () {
      var that = this;
      var data = {
        deviceState: this.deviceState,
        deviceSn: this.deviceSn,
        deviceType: this.deviceType,
        manufacturerId: this.manufacturerId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/device/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.listData = res.data.list;
          that.totals = res.data.totals;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 重置
    onReList: function () {
      this.deviceState = "";
      this.deviceSn = "";
      this.deviceType = "";
      this.manufacturerId = "";
      this.onList();
    },
    // 显示数据
    showData: function (type, data) {
      this.data_type = type
      this.data_deviceSn = data.deviceSn
      this.data_manufacturerId = data.manufacturerId
      this.data_dataTypeId = data.dataTypeId
      this.data_deviceType = data.deviceType
      this.data_reportingType = type
      if (type == 1) {
        this.data_title = `当日数据 [设备SN号: ${data.deviceSn}]`
        this.onDataList()
      } else {
        this.data_title = `历史数据 [设备SN号: ${data.deviceSn}]`
        this.data_show = true
        this.onDataList() // 默认是否直接查询全部历史数据
      }
    },
    // 获取数据
    onDataList: function () {
      var that = this;
      var data = {
        deviceSn: this.data_deviceSn,
        manufacturerId: this.data_manufacturerId,
        dataTypeId: this.data_dataTypeId,
        deviceType: this.data_deviceType,
        reportingType: this.data_reportingType,
        startTime: this.dateRange ? this.dateRange[0] : '',
        endTime: this.dateRange ? this.dateRange[1] : '' ,
        pageNum: this.data_pageNum,
        pageSize: this.data_pageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/device/reportRecord",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.data_listData = res.data.list[0].data;
          that.data_listKeys = Object.keys(res.data.list[0].watchHead[0]);
          that.data_listValues = Object.values(res.data.list[0].watchHead[0]);
          that.data_totals = res.data.totals;
          that.data_show = true
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 改变分页尺寸
    handleSizeChange: function (val) {
      this.data_pageSize = val
      this.onDataList()
    },
    // 关闭数据
    closeData: function () {
      this.dateRange = ["", ""]
      this.data_deviceSn = ""
      this.data_manufacturerId = ""
      this.data_deviceType = ""
      this.data_reportingType = ""

      this.data_type = 1
      this.data_show = false
      this.data_title = ""
      this.data_listData = []
      this.data_listKeys = []
      this.data_listValues = []
      this.data_pageNum = 1
      this.data_pageSize = 5
      this.data_totals = 0
    },
    // 添加
    handleAdd: function () {
      this.add_show = true
    },
    // 关闭
    handleClose: function () {
      this.add_show = false
      this.add_deviceSn = null
      this.add_deviceType = null
      this.add_manufacturerId = null
    },
    // 确定
    handleConfirm: function () {
      var that = this
      this.$confirm("此操作将保存数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onSaveData({
            id: null,
            deviceSn: that.add_deviceSn,
            deviceType: that.add_deviceType,
            manufacturerId: that.add_manufacturerId,
            reportingStatus: 0
          });
          this.add_show = false
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 修改上报状态
    handleReportingStatus(row) {
      console.log('handleReportingStatus', row);
      var msg
      var reportingStatus
      if (row.reportingStatus == 0) {
        msg = "不允许"
        reportingStatus = 1
      } else {
        msg = "允许"
        reportingStatus = 0
      }
      this.$confirm(`此操作将${msg}设备上报数据, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onSaveData({
            id: row.id,
            deviceSn: row.deviceSn,
            deviceType: row.deviceType,
            manufacturerId: row.manufacturerId,
            reportingStatus: reportingStatus
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 保存数据
    onSaveData: function (data) {
      var that = this;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/device/submit",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.$message({
              type: "success",
              message: "操作成功!",
            });
          } else {
            that.$message({
              type: "info",
              message: res.msg,
            });
          }
          that.onList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
